<template>
    <div class="checkout_fa">

        <MbrHeader ref="MbrHeader" v-bind:uid="$route.params.mbr"/>
        <Remise ref="Remise" v-on:refresh="reloadItem()"/>


        <!-- ======================================================== -->
        <template v-if="page==0">
            <vx-card class="w-full">
                <h2>Veuillez compléter les informations suivantes pour continuer :</h2>
                <vs-divider />
                <MbrFiche  v-bind:uid="$route.params.mbr" ref="MbrFiche" :all="0" @refresh="pageVerifOk" />
            </vx-card>
        </template>

        <!-- ======================================================== -->
        <template v-if="page==1">
            <vs-row>
                <vs-col v-show="items.length == 0" vs-type="flex" vs-justify="left" vs-align="top" vs-w="4" vs-xs="12">
                    <CatList ref="CatList" v-on:selected="addItem" style="margin-bottom:0px;" :type="1"/>
                </vs-col>

                <vs-col v-show="items.length == 0" vs-type="flex" vs-justify="left" vs-align="top" vs-w="8" vs-xs="12" class="item_select">
                    <vx-card class="w-full">
                        <h2>{{ name }}</h2>
                        <h3 class="my-5 text-primary">Nouveauté Studioconnect !</h3>
                        <p class="my-2 text-primary">Bienvenue dans la fonction Abonnement :</p>
                        <p class="my-2">&bullet; Sélectionnez votre produit, et enregistrez le contrat avec prélèvement Mollie. Ne soyez pas surpris, à la fin de cette opération aucune facture ne sera émise, seul le contrat sera généré. Les factures seront générées automatiquement chaque mois à reception des paiements par Mollie.</p>
                        <p class="my-2">&bullet; Vous pouvez désormais choisir votre date de prélèvement.</p>
                        <p class="my-2">&bullet; Si vous souhaitez que votre client bénéficie d'un nombre de séances mensuel limité, configurez un produit type abonnement avec expiration de 30 jours.</p>
                        <p class="mt-5 text-primary">Dans le cas d'une annulation d'un prélèvement SEPA :</p>
                        <p class="my-2">&bullet; Pour un prélèvement en cours ou à venir : aucune facture ne sera crée, aucun crédit alloué.</p>
                        <p class="my-2">&bullet; Dans le cas d'un refus de prélèvement déjà payé par le client : un avoir sera automatiquement généré par le système et les crédits retirés.</p>
                        <p class="mt-5 text-primary">Pour annuler un prélèvement/contrat :</p>
                        <p class="my-2">&bullet; Rendez-vous sur la fiche du client.</p>
                        <p class="my-2">&bullet; Sélectionnez Mollie.</p>
                        <p class="my-2">&bullet; Cliquez sur le bouton ANNULER Prélèvement.</p>
                        <p class="my-2">&bullet; Aucun AVOIR ou CONTREPASSATION n'est nécessaire.</p>
                        <p class="my-5">NB : Cette fonctionnalité permet d'obtenir un Chiffre d'affaires et une TVA collectée en temps réel basés sur les encaissements réalisés. Principe de comptabilité d'ENCAISSEMENT vs comptabilité d'ENGAGEMENT.</p>
                    </vx-card>
                </vs-col>

                <vs-col v-show="items.length > 0" vs-type="flex" vs-justify="left" vs-align="top" vs-w="12" vs-xs="12" class="item_select">
                    <vx-card class="w-full">
                        <h2>{{ name }}</h2>
                        <vs-divider />
                        <vs-table v-model="selected" :data="items" class="w-full" noDataText="Cliquez sur les catégories pour ajouter un article.">
                            <!-- header template -->
                            <template slot="header">
                            </template>

                            <!-- header table -->
                            <template slot="thead">
                                <vs-th sort-key="produit">Produit</vs-th>
                                <vs-th sort-key="ht">HT</vs-th>
                                <vs-th sort-key="tva">TVA</vs-th>
                                <vs-th sort-key="ttc">TTC</vs-th>
                                <vs-th ></vs-th>
                            </template>

                            <!--Body table-->
                            <template slot-scope="{data}">
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                                    <vs-td :data="data[indextr].name">
                                        <span>{{ StrCapital(data[indextr].name) }}</span>
                                        <template v-if="data[indextr].credit>0">
                                            <br/><small class="blue">+{{ data[indextr].credit }} Crédit
                                                <template v-if="data[indextr].expire>0">
                                                    <br/>pendant {{ data[indextr].expire }} jours
                                                </template>
                                            </small>
                                        </template>
                                    </vs-td>

                                    <vs-td :data="data[indextr].ht">
                                        <span>{{ data[indextr].ht.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].tva">
                                        <span>{{ data[indextr].tva }} %</span>
                                    </vs-td>

                                    <vs-td :data="data[indextr].ttc">
                                        <span>{{ data[indextr].ttc.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
                                    </vs-td>

                                    <vs-td>
                                        <vs-button color="primary" type="filled" class="cursor" v-on:click="addRemise(indextr)" style="padding:5px;margin-right:10px;">R.</vs-button>
                                        <vs-button color="danger" type="filled" class="cursor" v-on:click="removeItem(indextr)" style="padding:5px;">Sup.</vs-button>
                                    </vs-td>

                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-divider/>
                        <h2 class="align-right">La mensualité de cet abonnement sera de <b>{{ total_ttc.toFixed(devise.nb_decimal) }}</b> {{ devise.symbol }} TTC</h2>
                        <br/>
                        <vs-button color="success" type="filled" class="right" v-on:click="pagePayement()">Continuer</vs-button>
                        <br/><br/>
                    </vx-card>
                        
                </vs-col>
            </vs-row>
        </template>

        <template v-if="page==2">
            <vs-row>
                <vs-col vs-w="6">
                    <h1 class="align-left py-5 my-5">Abonnement <span class="text-primary">{{ items[0].name }}</span></h1>
                </vs-col>
                <vs-col vs-w="6">
                    <h1 class="align-right text-primary py-5 my-5">{{ total_ttc.toFixed(devise.nb_decimal) }} {{ devise.symbol }} TTC / mois</h1>
                </vs-col>
            </vs-row>
            <!-- <h1 class="align-left py-5 my-5">Abonnement <span class="text-primary">{{ items[0].name }}</span> {{ total_ttc.toFixed(devise.nb_decimal) }} {{ devise.symbol }} TTC / mois</h1> -->
            <vs-col>
                <vs-row>
                    <vx-card class="w-full">
                        <iframe v-if="!cgv_valide" v-bind:src="cgv_path" class="w-full" height="400" frameBorder="0"></iframe>
                        <h3 v-else>Conditions gérérales de vente validées <vs-icon icon="check" color="success" size="medium"></vs-icon></h3>
                        <vs-divider/>
                        <vs-checkbox color="success" v-model="cgv_valide">Je reconnais avoir pris connaissance des CGV</vs-checkbox>
                    </vx-card>
                </vs-row>
                <vs-row v-if="cgv_valide && cgv_content!== '' ">
                    <br />
                    <vs-divider/>
                    <br/>
                    <vx-card class="w-full">
                        <vs-button color="success" type="border" class="right ml-5 mb-2" v-if="!signature" v-on:click="validSign()">Valider</vs-button>
                        <vs-button color="primary" type="border" class="right mb-2" v-on:click="SignClear()">Nettoyer</vs-button>
                        <h2>Signature:</h2>
                        <vs-divider/>
                        <VueSignaturePad v-if="!signature"
                            id="signature"
                            width="100%"
                            height="300px"
                            ref="signaturePad"
                        />
                        <h3 v-else>Signature valide <vs-icon icon="check" color="success" size="medium"></vs-icon></h3>
                    </vx-card>
                </vs-row>
                <vs-row v-if="signature && cgv_valide">
                    <br />
                    <vs-divider/>
                    <br/>
                    <vx-card class="w-full">           
                        <h2>Paiement Mollie</h2>
                        <vs-divider/>
                        <br/>
                        <vx-card>
                            <p>IBAN Client:</p>
                            <vs-input class="inputx w-full" v-model="mollie_iban" /><br/>
                            <p>Nombre de mois de répétition : <br>
                            <span class="text-primary">Laisser 0 pour tacite reconduction.</span></p>
                            <vs-input class="inputx w-full" type="number" v-model="mollie_repeat" /><br/>
                            <p>Date de premier prélèvement :<br />
                            <span class="text-primary">Laisser vide pour prélèvement immédiat, ce jour de prélèvement sera identique les mois suivants.</span></p>
                            <cDate v-model="date" ref="cDate" :disableDates="disableDates" /><br/>
                        </vx-card>
                        <br/>
                        <br/>
                        <vs-button color="primary" type="filled" v-on:click="page = 1" >Retour</vs-button>
                        <vs-button color="success" type="filled" class="right nowrap" v-on:click="mollieValidation()" >Valider prélévement</vs-button>
                    </vx-card>
                </vs-row>
            </vs-col>
        </template>

        <!-- ======================================================== -->
        <template v-if="page==3">
            <vx-card>
                <h2 class="mb-2">Création de l'abonnement en cours, veuillez ne pas fermer cette fenêtre.</h2>
                <p v-if="send.mollie_id">N° de souscription Mollie : {{ send.mollie_id }}</p>
                <p v-else>Souscription Mollie en cours de création...</p>
                <p>Création du document : {{ doc }}</p>
                <p>Modification du membre : {{ updtmbr }}</p>
                <!-- <vs-button class="mt-5" color="primary" type="filled" v-on:click="goMember" >Voir la fiche membre</vs-button> -->
            </vx-card>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.blue{
    color:blue;
}
i.vs-icon {
    vertical-align: bottom;
}
.right{
    display:inline-block;
    float:right;
}
.item_view{
    padding:5px;
}
.item_view:hover{
    background-color:#ededed;
}
.align-right{
    text-align:right;
}
.vs-collapse{
    background-color:white;
}

@media only screen and (min-width: 900px)
{
    .item_select{
        padding-left:20px;
    }
}
@media only screen and (max-width: 899px)
{
    .item_select{
        margin-top:20px;
    }
}
</style>

<script>
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate';
import cDate from '@/components/app/date'

import MbrHeader from '@/components/member/header.vue'
import CatList from '@/components/checkout/categorie.vue'
import Remise from '@/components/checkout/remise.vue'
import MbrFiche from '@/components/member/ufiche.vue'

import autoRefresh from '@/assets/utils/autoRefresh'

// import Invoice  from '@/database/models/invoice'
// import Studio from '@/database/models/studio'
import Member  from '@/database/models/member'

export default {
    components:{
        MbrHeader,
        CatList,
        Remise,
        MbrFiche,
        cDate
    },
    data(){
        return{
            name: 'Abonnement',
            page:0,
            type:'fa',
            updtmbr:'En attente',
            doc:'En attente',
            msg: '',

            selected:[],
            items:[],
            total_ttc:0,
            total_paye:0,
            
            restant_ttc:0,
            total_credit:0,

            cgv_name:'',
            cgv_path:'',
            cgv_valide:0,
            cgv_content: '',

            mollie_id:'',
            mollie_iban:'',
            mollie_repeat: 0,
            mollie_start_date:undefined,
            mollie_secu:true,

            date:undefined,

            product:'',

            signature:undefined,

            payement : {
                name :'Prélévement',
                param:'pay_prele',
                value:'',
            },

            send: {},

            devise: {}
        }
	},
    computed: {
        disableDates() {
            return { to: new Date(this.tomorrow - 8640000) }
        },
        today() {
            return new Date()
        },
        tomorrow() {
            const tomorrow = new Date(this.today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            return tomorrow
        }
    },
	mounted:function()
	{
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
        
        //verif data membre
        this.pageVerifOk()

        this.cgv_path = this.$srvApi.buildUrl('studio/cgv/print/'+Fdate.getInputDate( new Date() ))

        this.$srvApi.Req('get', 'studio/cgv/print/'+Fdate.getInputDate( new Date() ))
        .then(( rep )=>{
            if( !rep.status )
                this.cgv_content = rep
        })

        //autoRefresh
		autoRefresh.SetCallback(()=>{
            if(this.$refs.MbrHeader.load)
            {
                this.$refs.MbrHeader.load( this.$route.params.mbr )
            }
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            this.selected=[],
            this.items=[]
            this.total_ttc=0
            
            this.total_credit=0

            this.cgv_path=''
            this.cgv_valide=0

            this.page = 0
            this.msg = ''
            this.mollie_iban=''
            this.mollie_repeat=''
            this.mollie_id = undefined


            //verif data membre
            this.pageVerifOk()
        }
    },
    methods:{
        StrCapital:function(str){
            return Utils.StrCapital(str);
        },
        addRemise( index )
        {
            this.$refs.Remise.open( this.items, index )
        },
        pageVerifOk()
        {
            window.scrollTo(0,0);
            this.page = 0
            Member.ficheMinComplete(this.$route.params.mbr)
            .then(()=>{
                //Ok fiche membre on passe au page suivante
                this.page = 1
            })
            .catch((msg)=>{
                //Message erreur fiche membre
                this.$refs.MbrFiche.setMsg( msg )
                //on remet la meme page
                this.page = 0
            })
        },
        addItem( item )
        {
            //Nb item a ajouté
            for( var i=0; i<item.nb; i++ )
            {
                // console.log(item)
                //Item
                this.items.push({
                    uid            : item.uid,
                    name           : item.produit,
                    description    : item.description,
                    credit         : item.credit ,
                    expire         : item.day    ,
                    remise         : 0           ,
                    tva            : item.tva,
                    ttc            : item.ttc,
                    ht             : item.ht,
                    machine        : item.machine,
                    compte_produit : item.compte_produit,
                    compte_tva     : item.compte_tva,
                    type           : item.type
                });
                //Data calc
                this.restant_ttc  += item.ttc
                this.total_ttc    += item.ttc
                this.total_credit += item.credit
            }
        },
        removeItem( posi )
        {
            this.restant_ttc  -= this.items[posi].ttc
            this.total_ttc    -= this.items[posi].ttc
            this.total_credit -= this.items[posi].credit
            this.items.splice(posi,1)
        },
        reloadItem()
        {
            this.restant_ttc  = 0
            this.total_ttc    = 0
            this.total_credit = 0  
            for( var i in this.items )
            {
                this.restant_ttc  += this.items[i].ttc
                this.total_ttc    += this.items[i].ttc
                this.total_credit += this.items[i].credit   
            }
        },
        pagePayement()
        {
            window.scrollTo(0,0);
            if( this.items.length>0)
                this.page = 2;
            else
                alert('Aucun produit séléctionné !')
        },
        SignClear()
        {   
            this.signature = 0
            this.$refs.signaturePad.clearSignature()
        },
        validSign()
        {
            this.signature = undefined

            if(this.$refs.signaturePad.isEmpty() == true)
                return alert('Aucune signature !')
            
            //get signature
            this.signature = this.$refs.signaturePad.saveSignature().data
        },     
        mollieValidation()
        {   
            //verif IBAN
            if(this.mollie_iban.length <= 14 )
                return alert('IBAN non complet !')

            if(this.mollie_repeat < 0)
                return alert('Veuillez saisir un nombre de répétition valide.')

            //verif date
            if( this.date === undefined )
                this.mollie_start_date = Fdate.getInputDate( this.tomorrow )
            else if(Fdate.getInputDate(this.date) < Fdate.getInputDate(this.tomorrow) ) {
                return alert("Un prélèvement à cette date créerait un paradoxe temporel dont l'issue engendrerait une réaction en chaîne qui pourrait déchirer le tissu-même du continuum espace-temps, provoquant la destruction totale de l'univers. Veuillez choisir une date dans le futur !")
            }
            else this.mollie_start_date = Fdate.getInputDate( this.date )

            if (!confirm("L'abonnement va être créé avec les informations fournies. Poursuivre?") )
                return

            this.page = 3
            window.scrollTo(0,0);
            this.enregistrer()
        },
        enregistrer() // là
        {

            let fitems = []
            for( let i=0; i<this.items.length;i++) {
                this.product = this.items[i].name
                // this.items[i].name = btoa(this.items[i].name)
                fitems.push(this.items[i])
                this.items[i].tva = Utils.NumberTwoVirgule(this.items[i].tva)
                this.items[i].ht = Utils.NumberTwoVirgule(this.items[i].ht)
                this.items[i].ttc = Utils.NumberTwoVirgule(this.items[i].ttc)
                this.payement.value += parseFloat( Number(this.items[i].ttc)).toFixed(this.devise.nb_decimal)
            }

            let paye = []
            paye.push(this.payement)

            this.send = {
                member    : this.$route.params.mbr,
                amount    : this.payement.value,
                iban      : this.mollie_iban,
                startDate : this.mollie_start_date,
                type      : this.type,
                payement  : JSON.stringify(paye),
                item      : JSON.stringify(fitems),
                times     : Number(this.mollie_repeat),
                nocgv     : true,
            }

            if(this.json_check)
                this.send.json_check = JSON.stringify(this.json_check)

            //payement mode mollie IBAN
            this.$srvApi.Req('post','mollie/abonnement', this.send)
            .then((rep)=>{
                if( rep.data.indexOf('sub_') == 0 )
                {
                    this.mollie_id = rep.data
                    this.send.mollie_id = this.mollie_id
                    this.send.name = 'subscription-'+this.mollie_id

                    this.createDocument()
                }
                else
                    alert( rep.data.split('Documentation')[0] )
            })
            .catch((err)=>{
                alert('Erreur API mollie')
                alert( err )
            })
        },
        updateMember() {
            this.updtmbr = 'En cours'
            Member.getMemory( this.$route.params.mbr, (mbr)=> {
                //si membre essai alors activation
                if( mbr.type=='e') {
                    Member.update(this.$route.params.mbr, {
                        type:'m',
                        active:1
                    })
                    .then(()=>{
                        this.updtmbr = "Effectuée"
                    })
                } else this.updtmbr = "Effectuée"
            })
        },
        createDocument() {
            this.doc = "En cours"
            this.send.cgv = this.cgv_content

            if(this.product)
                this.send.product = this.product

            this.send.sign = this.signature
            
            //Envoi des data pour création
            this.$srvApi.Req('post', 'user/subscription/'+this.$route.params.mbr, {
                uid  : this.$route.params.mbr,
                json : JSON.stringify(this.send)
            })
            .then(( rep )=>
            {
                //err ?
                if(rep.status != 'success')
                    this.doc = "Impossible de créer le document de souscription !"
                this.doc ="Souscription créée"
                this.updateMember()
                this.$router.push('/admin/file/'+ encodeURI(btoa('studio/cloudmember/'+this.$route.params.mbr+'/'+rep.data)) );
            })
        },
        printReValue( value )
        {
            if(value!="" ) 
                return value.toFixed(this.devise.nb_decimal)
            return "0.00"
        }
    }
};

</script>